import { Routes } from '@angular/router';

import { DwAuthGuardService } from '@webdpt/framework';

export const IMPLEMENTATION_ROUTES: Routes = [
  // 設定應用開發應用模組路由
  // {
  //   path: '', // 首頁
  //   pathMatch: 'prefix',
  //   loadChildren: './index/index.module#IndexModule',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'index'
  //     }
  //   }
  // },
  {
    path: '', // 首頁
    pathMatch: 'prefix',
    loadChildren: './home/home.module#HomeModule',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'home'
      }
    }
  },
  // {
  //   path: 'fxdi301',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/d/fxdi301/fxdi301.module#Fxdi301Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxdi301'
  //     }
  //   }
  // },
  // {
  //   path: 'fxxd001',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/x/fxxd001/fxxd001.module#Fxxd001Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxxd001'
  //     }
  //   }
  // },
  {
    path: 'fxxd007',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd007/fxxd007.module#Fxxd007Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd007'
      }
    }
  },
  {
    path: 'fxxd067',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd067/fxxd067.module#Fxxd067Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd067'
      }
    }
  },
  {
    path: 'fxxd008',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd008/fxxd008.module#Fxxd008Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd008'
      }
    }
  },
  {
    path: 'fxxd031',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd031/fxxd031.module#Fxxd031Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd031'
      }
    }
  },
  {
    path: 'fxxd036',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd036/fxxd036.module#Fxxd036Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd036'
      }
    }
  },
  {
    path: 'fxxd009',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd009/fxxd009.module#Fxxd009Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd009'
      }
    }
  },
  {
    path: 'fxxd010',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd010/fxxd010.module#Fxxd010Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd010'
      }
    }
  },
  {
    path: 'fxxd035',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd035/fxxd035.module#Fxxd035Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd035'
      }
    }
  },
  {
    path: 'fxxd041',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd041/fxxd041.module#Fxxd041Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd041'
      }
    }
  },
  {
    path: 'fxxd042',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd042/fxxd042.module#Fxxd042Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd042'
      }
    }
  },
  {
    path: 'fxxd043',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd043/fxxd043.module#Fxxd043Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd043'
      }
    }
  },
  // {
  //   path: 'fxxi001',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/x/fxxi001/fxxi001.module#Fxxi001Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxxi001'
  //     }
  //   }
  // },
  // {
  //   path: 'fxxi002',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/x/fxxi002/fxxi002.module#Fxxi002Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxxi002'
  //     }
  //   }
  // },
  // {
  //   path: 'fxeq068',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/e/fxeq068/fxeq068.module#Fxeq068Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxeq068'
  //     }
  //   }
  // },
  // {
  //   path: 'fxeq069',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/e/fxeq069/fxeq069.module#Fxeq069Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxeq069'
  //     }
  //   }
  // },
  // {
  //   path: 'fxeq070',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/e/fxeq070/fxeq070.module#Fxeq070Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxeq070'
  //     }
  //   }
  // },
  // {
  //   path: 'fxeq093',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/e/fxeq093/fxeq093.module#Fxeq093Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxeq093'
  //     }
  //   }
  // },
  // {
  //   path: 'fxeq096',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/e/fxeq096/fxeq096.module#Fxeq096Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxeq096'
  //     }
  //   }
  // },
  // {
  //   path: 'fxeq097',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/e/fxeq097/fxeq097.module#Fxeq097Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxeq097'
  //     }
  //   }
  // },
  // {
  //   path: 'fxeq098',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/e/fxeq098/fxeq098.module#Fxeq098Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxeq098'
  //     }
  //   }
  // },
  {
    path: 'fxeq099',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq099/fxeq099.module#Fxeq099Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq099'
      }
    }
  },
  //   {
  //     path: 'fxeq100',
  //     pathMatch: 'prefix',
  //     loadChildren: 'app/implementation/programs/e/fxeq100/fxeq100.module#Fxeq100Module',
  //     canLoad: [DwAuthGuardService],
  //     data: {
  //       dwRouteData: {
  //         dwAuthId: 'fxeq100'
  //       }
  //     }
  //   },
  // {
  //   path: 'fxeq101',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/e/fxeq101/fxeq101.module#Fxeq101Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxeq101'
  //     }
  //   }
  // },
  // {
  //   path: 'fxeq102',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/e/fxeq102/fxeq102.module#Fxeq102Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxeq102'
  //     }
  //   }
  // },
  // {
  //   path: 'fxeq103',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/e/fxeq103/fxeq103.module#Fxeq103Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxeq103'
  //     }
  //   }
  // },
  {
    path: 'fxeq104',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq104/fxeq104.module#Fxeq104Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq104'
      }
    }
  },
  {
    path: 'fxeq105',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq105/fxeq105.module#Fxeq105Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq105'
      }
    }
  },
  // {
  //   path: 'fxeq106',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/e/fxeq106/fxeq106.module#Fxeq106Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxeq106'
  //     }
  //   }
  // },
  // {
  //   path: 'fxeq107',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/e/fxeq107/fxeq107.module#Fxeq107Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxeq107'
  //     }
  //   }
  // },
  // {
  //   path: 'fxeq111',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/e/fxeq111/fxeq111.module#Fxeq111Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxeq111'
  //     }
  //   }
  // },
  {
    path: 'fxeq118',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq118/fxeq118.module#Fxeq118Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq118'
      }
    }
  },
  {
    path: 'fxeq115',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq115/fxeq115.module#Fxeq115Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq115'
      }
    }
  },
  // {
  //   path: 'fxb-i01',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/fxb-i01/fxb-i01.module#FxbI01Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxb-i01'
  //     }
  //   }
  // },
  {
    path: 'fxad003',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxad003/fxad003.module#Fxad003Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxad003'
      }
    }
  },
  {
    path: 'fxci015',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/c/fxci015/fxci015.module#Fxci015Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxci015'
      }
    }
  },
  {
    path: 'fxci016',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/c/fxci016/fxci016.module#Fxci016Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxci016'
      }
    }
  },
  {
    path: 'fxci017',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/c/fxci017/fxci017.module#Fxci017Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxci017'
      }
    }
  },
  {
    path: 'fxeq095',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq095/fxeq095.module#Fxeq095Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq095'
      }
    }
  },
  {
    path: 'fxdi035',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi035/fxdi035.module#Fxdi035Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi035'
      }
    }
  },
  {
    path: 'fxdi036',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi036/fxdi036.module#Fxdi036Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi036'
      }
    }
  },
  {
    path: 'fxdi037',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi037/fxdi037.module#Fxdi037Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi037'
      }
    }
  },
  {
    path: 'fxdi019',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi019/fxdi019.module#Fxdi019Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi019'
      }
    }
  },
  {
    path: 'fxai002',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai002/fxai002.module#Fxai002Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai002'
      }
    }
  },
  {
    path: 'fxai004',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai004/fxai004.module#Fxai004Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai004'
      }
    }
  },
  {
    path: 'fxai099',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai099/fxai099.module#Fxai099Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai099'
      }
    }
  },
  {
    path: 'fxgd010',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/g/fxgd010/fxgd010.module#Fxgd010Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxgd010'
      }
    }
  },
  {
    path: 'fxgd011',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/g/fxgd011/fxgd011.module#Fxgd011Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxgd011'
      }
    }
  },
  {
    path: 'fxgd012',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/g/fxgd012/fxgd012.module#Fxgd012Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxgd012'
      }
    }
  },
  {
    path: 'fxgq002',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/g/fxgq002/fxgq002.module#Fxgq002Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxgq002'
      }
    }
  },
  {
    path: 'fxgq012',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/g/fxgq012/fxgq012.module#Fxgq012Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxgq012'
      }
    }
  },
  {
    path: 'fxgd009',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/g/fxgd009/fxgd009.module#Fxgd009Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxgd009'
      }
    }
  },
  {
    path: 'fxii010',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/i/fxii010/fxii010.module#Fxii010Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxii010'
      }
    }
  },
  {
    path: 'fxdi030',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi030/fxdi030.module#Fxdi030Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi030'
      }
    }
  },
  {
    path: 'fxdi031',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi031/fxdi031.module#Fxdi031Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi031'
      }
    }
  },
  {
    path: 'fxai031',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai031/fxai031.module#Fxai031Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai031'
      }
    }
  },
  {
    path: 'fxdi032',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi032/fxdi032.module#Fxdi032Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi032'
      }
    }
  },
  {
    path: 'fxdi034',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi034/fxdi034.module#Fxdi034Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi034'
      }
    }
  },
  {
    path: 'fxai033',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai033/fxai033.module#Fxai033Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai033'
      }
    }
  },
  // fxai034-系统信息设置作业
  {
    path: 'fxai034',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai034/fxai034.module#Fxai034Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai034'
      }
    }
  },
  {
    path: 'fxai035',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai035/fxai035.module#Fxai035Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai035'
      }
    }
  },
  {
    path: 'fxai036',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai036/fxai036.module#Fxai036Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai036'
      }
    }
  },
  // fxai005-系统信息设置作业
  {
    path: 'fxai005',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai005/fxai005.module#Fxai005Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai005'
      }
    }
  },
  {
    path: 'fx-666',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/fx-666/fx-666.module#Fx666Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fx-666'
      }
    }
  },
  {
    path: 'fxgrid',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/fxgrid/fxgrid.module#FxGridModule',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxgrid'
      }
    }
  },
  {
    path: 'fxei003',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxei003/fxei003.module#Fxei003Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxei003'
      }
    }
  },
  {
    path: 'fxei004',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxei004/fxei004.module#Fxei004Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxei004'
      }
    }
  },
  {
    path: 'fxai001',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai001/fxai001.module#Fxai001Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai001'
      }
    }
  },
  {
    path: 'fxaq003',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxaq003/fxaq003.module#Fxaq003Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxaq003'
      }
    }
  },
  {
    path: 'fxaq903',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxaq903/fxaq903.module#Fxaq903Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxaq903'
      }
    }
  },
  {
    path: 'fxaq904',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxaq904/fxaq904.module#Fxaq904Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxaq904'
      }
    }
  },
  // {
  //   path: 'fxdd303',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/d/fxdd303/fxdd303.module#Fxdd303Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxdd303'
  //     }
  //   }
  // },
  // {
  //   path: 'fxdd036',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/d/fxdd036/fxdd036.module#Fxdd036Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxdd036'
  //     }
  //   }
  // },
  // {
  //   path: 'fxdd037',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/d/fxdd037/fxdd037.module#Fxdd037Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxdd037'
  //     }
  //   }
  // },
  {
    path: 'fxdd071',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdd071/fxdd071.module#Fxdd071Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdd071'
      }
    }
  },
  {
    path: 'fxdd072',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdd072/fxdd072.module#Fxdd072Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdd072'
      }
    }
  },
  {
    path: 'fxdd073',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdd073/fxdd073.module#Fxdd073Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdd073'
      }
    }
  },
  {
    path: 'fxdd074',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdd074/fxdd074.module#Fxdd074Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdd074'
      }
    }
  },
  // {
  //   path: 'fxdi038',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/d/fxdi038/fxdi038.module#Fxdi038Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxdi038'
  //     }
  //   }
  // },
  // {
  //   path: 'fxdi040',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/d/fxdi040/fxdi040.module#Fxdi040Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxdi040'
  //     }
  //   }
  // },
  {
    path: 'fxdi041',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi041/fxdi041.module#Fxdi041Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi041'
      }
    }
  },
  {
    path: 'fxdi045',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi045/fxdi045.module#Fxdi045Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi045'
      }
    }
  },
  {
    path: 'fxdi046',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi046/fxdi046.module#Fxdi046Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi046'
      }
    }
  },
  {
    path: 'fxdk001',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdk001/fxdk001.module#Fxdk001Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdk001'
      }
    }
  },
  {
    path: 'fxaq033',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxaq033/fxaq033.module#Fxaq033Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxaq033'
      }
    }
  },
  {
    path: 'fxeq031',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq031/fxeq031.module#Fxeq031Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq031'
      }
    }
  },
  {
    path: 'fxei002',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxei002/fxei002.module#Fxei002Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxei002'
      }
    }
  },

  // {
  //   path: 'fxdd010',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/d/fxdd010/fxdd010.module#Fxdd010Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxdd010'
  //     }
  //   }
  // },
  // {
  //   path: 'fxeq033',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/e/fxeq033/fxeq033.module#Fxeq033Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxeq033'
  //     }
  //   }
  // },
  // {
  //   path: 'fxeq038',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/e/fxeq038/fxeq038.module#Fxeq038Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxeq038'
  //     }
  //   }
  // },
  // {
  //   path: 'fxeq060',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/e/fxeq060/fxeq060.module#Fxeq060Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxeq060'
  //     }
  //   }
  // },
  {
    path: 'fxeq066',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq066/fxeq066.module#Fxeq066Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq066'
      }
    }
  },
  // {
  //   path: 'fxeq065',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/e/fxeq065/fxeq065.module#Fxeq065Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxeq065'
  //     }
  //   }
  // },
  // {
  //   path: 'fxeq073',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/e/fxeq073/fxeq073.module#Fxeq073Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxeq073'
  //     }
  //   }
  // },
  {
    path: 'fxeq074',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq074/fxeq074.module#Fxeq074Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq074'
      }
    }
  },
  // {
  //   path: 'fxeq075',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/e/fxeq075/fxeq075.module#Fxeq075Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxeq075'
  //     }
  //   }
  // },
  // {
  //   path: 'fxeq076',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/e/fxeq076/fxeq076.module#Fxeq076Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxeq076'
  //     }
  //   }
  // },
  {
    path: 'fxeq081',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq081/fxeq081.module#Fxeq081Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq081'
      }
    }
  },
  {
    path: 'fxeq082',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq082/fxeq082.module#Fxeq082Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq082'
      }
    }
  },
  {
    path: 'fxeq083',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq083/fxeq083.module#Fxeq083Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq083'
      }
    }
  },
  {
    path: 'fxeq084',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq084/fxeq084.module#Fxeq084Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq084'
      }
    }
  },
  // {
  //   path: 'fxeq085',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/e/fxeq085/fxeq085.module#Fxeq085Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxeq085'
  //     }
  //   }
  // },
  // {
  //   path: 'fxeq087',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/e/fxeq087/fxeq087.module#Fxeq087Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxeq087'
  //     }
  //   }
  // },
  {
    path: 'fxeq088',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq088/fxeq088.module#Fxeq088Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq088'
      }
    }
  },
  // {
  //   path: 'fxeq089',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/e/fxeq089/fxeq089.module#Fxeq089Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxeq089'
  //     }
  //   }
  // },
  // {
  //   path: 'fxdi302',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/d/fxdi302/fxdi302.module#Fxdi302Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxdi302'
  //     }
  //   }
  // },
  {
    path: 'fxeq040',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq040/fxeq040.module#Fxeq040Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq040'
      }
    }
  },
  {
    path: 'fxgp001',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/g/fxgp001/fxgp001.module#Fxgp001Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxgp001'
      }
    }
  },
  // {
  //   path: 'fxfi002',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/f/fxfi002/fxfi002.module#Fxfi002Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxfi002'
  //     }
  //   }
  // },
  {
    path: 'fxxi003',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxi003/fxxi003.module#Fxxi003Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxi003'
      }
    }
  },
  {
    path: 'fxxi004',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxi004/fxxi004.module#Fxxi004Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxi004'
      }
    }
  },
  {
    path: 'fxhd002',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/h/fxhd002/fxhd002.module#Fxhd002Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxhd002'
      }
    }
  },
  {
    path: 'fxhd003',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/h/fxhd003/fxhd003.module#Fxhd003Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxhd003'
      }
    }
  },
  {
    path: 'fxhd004',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/h/fxhd004/fxhd004.module#Fxhd004Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxhd004'
      }
    }
  },
  {
    path: 'fxhd005',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/h/fxhd005/fxhd005.module#Fxhd005Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxhd005'
      }
    }
  },
  {
    path: 'fxhd007',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/h/fxhd007/fxhd007.module#Fxhd007Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxhd007'
      }
    }
  },
  {
    path: 'fxdq003',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdq003/fxdq003.module#Fxdq003Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdq003'
      }
    }
  },
  {
    path: 'fxdq014',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdq014/fxdq014.module#Fxdq014Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdq014'
      }
    }
  },
  {
    path: 'fxeq041',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq041/fxeq041.module#Fxeq041Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq041'
      }
    }
  },
  {
    path: 'fxeq042',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq042/fxeq042.module#Fxeq042Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq042'
      }
    }
  },
  {
    path: 'fxeq043',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq043/fxeq043.module#Fxeq043Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq043'
      }
    }
  },
  {
    path: 'fxeq044',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq044/fxeq044.module#Fxeq044Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq044'
      }
    }
  },
  {
    path: 'fxeq045',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq045/fxeq045.module#Fxeq045Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq045'
      }
    }
  },
  {
    path: 'fxdi029',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi029/fxdi029.module#Fxdi029Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi029'
      }
    }
  },
  // {
  //   path: 'fxgd008',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/g/fxgd008/fxgd008.module#Fxgd008Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxgd008'
  //     }
  //   }
  // },
  {
    path: 'fxci013',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/c/fxci013/fxci013.module#Fxci013Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxci013'
      }
    }
  },
  {
    path: 'fxdi033',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi033/fxdi033.module#Fxdi033Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi033'
      }
    }
  },
  {
    path: 'fxhq002',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/h/fxhq002/fxhq002.module#Fxhq002Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxhq002'
      }
    }
  },
  {
    path: 'fxgd006',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/g/fxgd006/fxgd006.module#Fxgd006Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxgd006'
      }
    }
  },
  {
    path: 'fxhq001',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/h/fxhq001/fxhq001.module#Fxhq001Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxhq001'
      }
    }
  },
  {
    path: 'fxed006',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed006/fxed006.module#Fxed006Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed006'
      }
    }
  },
  {
    path: 'fxed056',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed056/fxed056.module#Fxed056Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed056'
      }
    }
  },
  {
    path: 'fxxd024',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd024/fxxd024.module#Fxxd024Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd024'
      }
    }
  },
  {
    path: 'fxxd066',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd066/fxxd066.module#Fxxd066Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd066'
      }
    }
  },
  {
    path: 'fxxd018',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd018/fxxd018.module#Fxxd018Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd018'
      }
    }
  },
  {
    path: 'fxxd026',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd026/fxxd026.module#Fxxd026Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd026'
      }
    }
  },
  {
    path: 'fxxd028',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd028/fxxd028.module#Fxxd028Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd028'
      }
    }
  },
  {
    path: 'fxxd029',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd029/fxxd029.module#Fxxd029Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd029'
      }
    }
  },
  {
    path: 'fxed024',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed024/fxed024.module#Fxed024Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed024'
      }
    }
  },
  {
    path: 'fxed026',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed026/fxed026.module#Fxed026Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed026'
      }
    }
  },
  {
    path: 'fxed027',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed027/fxed027.module#Fxed027Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed027'
      }
    }
  },
  {
    path: 'fxeq032',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq032/fxeq032.module#Fxeq032Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq032'
      }
    }
  },
  {
    path: 'fxiq001',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/i/fxiq001/fxiq001.module#Fxiq001Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxiq001'
      }
    }
  },
  {
    path: 'fxiq002',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/i/fxiq002/fxiq002.module#Fxiq002Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxiq002'
      }
    }
  },
  {
    path: 'fxip001',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/i/fxip001/fxip001.module#Fxip001Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxip001'
      }
    }
  },
  {
    path: 'fxed040',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed040/fxed040.module#Fxed040Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed040'
      }
    }
  },
  {
    path: 'fxed041',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed041/fxed041.module#Fxed041Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed041'
      }
    }
  },
  {
    path: 'fxed042',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed042/fxed042.module#Fxed042Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed042'
      }
    }
  },
  {
    path: 'fxai006',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai006/fxai006.module#Fxai006Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai006'
      }
    }
  },
  {
    path: 'fxai017',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai017/fxai017.module#Fxai017Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai017'
      }
    }
  },
  {
    path: 'fxgd101',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/g/fxgd101/fxgd101.module#fxgd101Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxgd101'
      }
    }
  },
  {
    path: 'fxgd102',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/g/fxgd102/fxgd102.module#Fxgd102Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxgd102'
      }
    }
  },
  {
    path: 'fxgd103',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/g/fxgd103/fxgd103.module#Fxgd103Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxgd103'
      }
    }
  },
  {
    path: 'fxai007',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai007/fxai007.module#Fxai007Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai007'
      }
    }
  },
  {
    path: 'fxai010',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai010/fxai010.module#Fxai010Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai010'
      }
    }
  },
  {
    path: 'fxai011',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai011/fxai011.module#Fxai011Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai011'
      }
    }
  },
  {
    path: 'fxai012',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai012/fxai012.module#Fxai012Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai012'
      }
    }
  },
  {
    path: 'fxai013',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai013/fxai013.module#Fxai013Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai013'
      }
    }
  },
  {
    path: 'fxgd100',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/g/fxgd100/fxgd100.module#Fxgd100Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxgd100'
      }
    }
  },
  {
    path: 'fxai014',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai014/fxai014.module#Fxai014Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai014'
      }
    }
  },
  {
    path: 'fxai015',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai015/fxai015.module#Fxai015Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai015'
      }
    }
  },
  {
    path: 'fxai021',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai021/fxai021.module#Fxai021Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai021'
      }
    }
  },
  {
    path: 'fxai022',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai022/fxai022.module#Fxai022Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai022'
      }
    }
  },
  {
    path: 'fxai023',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai023/fxai023.module#Fxai023Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai023'
      }
    }
  },
  {
    path: 'fxbi002',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/b/fxbi002/fxbi002.module#Fxbi002Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxbi002'
      }
    }
  },
  {
    path: 'fxgi002',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/g/fxgi002/fxgi002.module#Fxgi002Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxgi002'
      }
    }
  },
  {
    path: 'fxgi003',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/g/fxgi003/fxgi003.module#Fxgi003Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxgi003'
      }
    }
  },
  {
    path: 'fxbi003',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/b/fxbi003/fxbi003.module#Fxbi003Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxbi003'
      }
    }
  },
  {
    path: 'fxbi005',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/b/fxbi005/fxbi005.module#Fxbi005Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxbi005'
      }
    }
  },
  {
    path: 'fxbi006',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/b/fxbi006/fxbi006.module#Fxbi006Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxbi006'
      }
    }
  },
  {
    path: 'fxbi010',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/b/fxbi010/fxbi010.module#Fxbi010Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxbi010'
      }
    }
  },
  {
    path: 'fxdq053',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdq053/fxdq053.module#Fxdq053Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdq053'
      }
    }
  },
  {
    path: 'fxdq100',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdq100/fxdq100.module#Fxdq100Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdq100'
      }
    }
  },
  {
    path: 'fxbi011',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/b/fxbi011/fxbi011.module#Fxbi011Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxbi011'
      }
    }
  },
  // {
  //   path: 'fxbi012',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/b/fxbi012/fxbi012.module#Fxbi012Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxbi012'
  //     }
  //   }
  // },
  {
    path: 'fxbi013',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/b/fxbi013/fxbi013.module#Fxbi013Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxbi013'
      }
    }
  },
  // {
  //   path: 'fxbi014',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/b/fxbi014/fxbi014.module#Fxbi014Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxbi014'
  //     }
  //   }
  // },
  // {
  //   path: 'fxbi016',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/b/fxbi016/fxbi016.module#Fxbi016Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxbi016'
  //     }
  //   }
  // },
  {
    path: 'fxdq300',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdq300/fxdq300.module#Fxdq300Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdq300'
      }
    }
  },
  {
    path: 'fxbi024',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/b/fxbi024/fxbi024.module#Fxbi024Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxbi024'
      }
    }
  },
  {
    path: 'fxbi025',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/b/fxbi025/fxbi025.module#Fxbi025Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxbi025'
      }
    }
  },
  {
    path: 'fxbi026',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/b/fxbi026/fxbi026.module#Fxbi026Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxbi026'
      }
    }
  },
  {
    path: 'fxbi027',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/b/fxbi027/fxbi027.module#Fxbi027Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxbi027'
      }
    }
  },
  // {
  //   path: 'fxbi028',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/b/fxbi028/fxbi028.module#Fxbi028Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxbi028'
  //     }
  //   }
  // },
  // {
  //   path: 'fxbi029',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/b/fxbi029/fxbi029.module#Fxbi029Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxbi029'
  //     }
  //   }
  // },
  // {
  //   path: 'fxbi030',
  //   pathMatch: 'prefix',
  //   loadChildren: 'app/implementation/programs/b/fxbi030/fxbi030.module#Fxbi030Module',
  //   canLoad: [DwAuthGuardService],
  //   data: {
  //     dwRouteData: {
  //       dwAuthId: 'fxbi030'
  //     }
  //   }
  // },
  {
    path: 'fxbi031',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/b/fxbi031/fxbi031.module#Fxbi031Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxbi031'
      }
    }
  },
  {
    path: 'fxbi032',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/b/fxbi032/fxbi032.module#Fxbi032Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxbi032'
      }
    }
  },
  {
    path: 'fxbi033',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/b/fxbi033/fxbi033.module#Fxbi033Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxbi033'
      }
    }
  },
  {
    path: 'fxai024',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai024/fxai024.module#Fxai024Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai024'
      }
    }
  },
  {
    path: 'fxbi001',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/b/fxbi001/fxbi001.module#Fxbi001Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxbi001'
      }
    }
  },
  {
    path: 'fxdi025',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi025/fxdi025.module#Fxdi025Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi025'
      }
    }
  },
  {
    path: 'fxdd001',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdd001/fxdd001.module#Fxdd001Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdd001'
      }
    }
  },
  {
    path: 'fxdd002',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdd002/fxdd002.module#Fxdd002Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdd002'
      }
    }
  },
  {
    path: 'fxdi001',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi001/fxdi001.module#Fxdi001Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi001'
      }
    }
  },
  {
    path: 'fxdi002',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi002/fxdi002.module#Fxdi002Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi002'
      }
    }
  },
  {
    path: 'fxdi004',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi004/fxdi004.module#Fxdi004Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi004'
      }
    }
  },
  {
    path: 'fxdi007',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi007/fxdi007.module#Fxdi007Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi007'
      }
    }
  },
  {
    path: 'fxdi008',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi008/fxdi008.module#Fxdi008Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi008'
      }
    }
  },
  {
    path: 'fxdi009',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi009/fxdi009.module#Fxdi009Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi009'
      }
    }
  },
  {
    path: 'fxdi011',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi011/fxdi011.module#Fxdi011Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi011'
      }
    }
  },
  {
    path: 'fxdi012',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi012/fxdi012.module#Fxdi012Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi012'
      }
    }
  },
  {
    path: 'fxdi013',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi013/fxdi013.module#Fxdi013Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi013'
      }
    }
  },
  {
    path: 'fxdi014',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi014/fxdi014.module#Fxdi014Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi014'
      }
    }
  },
  {
    path: 'fxdi015',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi015/fxdi015.module#Fxdi015Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi015'
      }
    }
  },
  {
    path: 'fxdi016',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi016/fxdi016.module#Fxdi016Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi016'
      }
    }
  },
  {
    path: 'fxgd007',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/g/fxgd007/fxgd007.module#Fxgd007Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxgd007'
      }
    }
  },
  {
    path: 'fxdi020',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi020/fxdi020.module#Fxdi020Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi020'
      }
    }
  },
  {
    path: 'fxdi021',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi021/fxdi021.module#Fxdi021Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi021'
      }
    }
  },
  {
    path: 'fxdi022',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi022/fxdi022.module#Fxdi022Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi022'
      }
    }
  },
  {
    path: 'fxdi023',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi023/fxdi023.module#Fxdi023Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi023'
      }
    }
  },
  {
    path: 'fxdi050',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi050/fxdi050.module#Fxdi050Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi050'
      }
    }
  },
  {
    path: 'fxdi053',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi053/fxdi053.module#Fxdi053Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi053'
      }
    }
  },
  {
    path: 'fxdi054',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi054/fxdi054.module#Fxdi054Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi054'
      }
    }
  },
  {
    path: 'fxdi082',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi082/fxdi082.module#Fxdi082Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi082'
      }
    }
  },
  {
    path: 'fxdi083',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi083/fxdi083.module#Fxdi083Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi083'
      }
    }
  },
  {
    path: 'fxdi086',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi086/fxdi086.module#Fxdi086Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi086'
      }
    }
  },
  {
    path: 'fxdi087',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi087/fxdi087.module#Fxdi087Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi087'
      }
    }
  },
  {
    path: 'fxdi088',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi088/fxdi088.module#Fxdi088Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi088'
      }
    }
  },
  {
    path: 'fxdi666',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi666/fxdi666.module#Fxdi666Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi666'
      }
    }
  },
  {
    path: 'fxdi089',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi089/fxdi089.module#Fxdi089Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi089'
      }
    }
  },
  {
    path: 'fxdi090',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi090/fxdi090.module#Fxdi090Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi090'
      }
    }
  },
  {
    path: 'fxdi091',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi091/fxdi091.module#Fxdi091Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi091'
      }
    }
  },
  {
    path: 'fxdi092',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi092/fxdi092.module#Fxdi092Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi092'
      }
    }
  },
  {
    path: 'fxdi093',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi093/fxdi093.module#Fxdi093Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi93'
      }
    }
  },
  {
    path: 'fxdi095',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi095/fxdi095.module#Fxdi095Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi95'
      }
    }
  },
  {
    path: 'fxdi096',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi096/fxdi096.module#Fxdi096Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi96'
      }
    }
  },
  {
    path: 'fxdi097',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi097/fxdi097.module#Fxdi097Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi97'
      }
    }
  },
  {
    path: 'fxdi105',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi105/fxdi105.module#Fxdi105Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi97'
      }
    }
  },
  {
    path: 'fxdi106',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi106/fxdi106.module#Fxdi106Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi97'
      }
    }
  },
  {
    path: 'fxdi099',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi099/fxdi099.module#Fxdi099Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi099'
      }
    }
  },
  {
    path: 'fxdi103',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi103/fxdi103.module#Fxdi103Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi103'
      }
    }
  },
  {
    path: 'fxdi104',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi104/fxdi104.module#Fxdi104Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi104'
      }
    }
  },
  {
    path: 'fxdi101',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi101/fxdi101.module#Fxdi101Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi101'
      }
    }
  },
  {
    path: 'fxdd083',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdd083/fxdd083.module#Fxdd083Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdd083'
      }
    }
  },
  {
    path: 'fxdi070',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi070/fxdi070.module#Fxdi070Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi070'
      }
    }
  },
  {
    path: 'fxdi071',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi071/fxdi071.module#Fxdi071Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi071'
      }
    }
  },
  {
    path: 'fxdq011',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdq011/fxdq011.module#Fxdq011Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdq011'
      }
    }
  },
  {
    path: 'fxhq003',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/h/fxhq003/fxhq003.module#Fxhq003Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxhq003'
      }
    }
  },
  {
    path: 'fxhq004',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/h/fxhq004/fxhq004.module#Fxhq004Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxhq004'
      }
    }
  },
  {
    path: 'fxhq005',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/h/fxhq005/fxhq005.module#Fxhq005Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxhq005'
      }
    }
  },
  {
    path: 'fxdi055',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi055/fxdi055.module#Fxdi055Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi055'
      }
    }
  },
  {
    path: 'fxdi057',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi057/fxdi057.module#Fxdi057Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi057'
      }
    }
  },
  {
    path: 'fxdi058',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi058/fxdi058.module#Fxdi058Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi058'
      }
    }
  },
  {
    path: 'fxdd056',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdd056/fxdd056.module#Fxdd056Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdd056'
      }
    }
  },
  {
    path: 'fxdi100',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi100/fxdi100.module#Fxdi100Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi100'
      }
    }
  },
  {
    path: 'fxdi200',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi200/fxdi200.module#Fxdi200Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi200'
      }
    }
  }, {
    path: 'fxdd003',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdd003/fxdd003.module#Fxdd003Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdd003'
      }
    }
  },
  {
    path: 'fxdd005',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdd005/fxdd005.module#Fxdd005Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdd005'
      }
    }
  },
  {
    path: 'fxdd006',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdd006/fxdd006.module#Fxdd006Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdd006'
      }
    }
  },
  {
    path: 'fxdd007',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdd007/fxdd007.module#Fxdd007Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdd007'
      }
    }
  },
  {
    path: 'fxdd011',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdd011/fxdd011.module#Fxdd011Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdd011'
      }
    }
  },
  {
    path: 'fxdd012',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdd012/fxdd012.module#Fxdd012Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdd012'
      }
    }
  },
  {
    path: 'fxdi300',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi300/fxdi300.module#Fxdi300Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi300'
      }
    }
  },
  {
    path: 'fxdi400',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi400/fxdi400.module#Fxdi400Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi400'
      }
    }
  },
  {
    path: 'fxdi401',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi401/fxdi401.module#Fxdi401Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi401'
      }
    }
  },
  {
    path: 'fxdi402',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi402/fxdi402.module#Fxdi402Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi402'
      }
    }
  },
  {
    path: 'fxdi403',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi403/fxdi403.module#Fxdi403Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi403'
      }
    }
  },
  {
    path: 'fxdi404',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi404/fxdi404.module#Fxdi404Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi404'
      }
    }
  },
  {
    path: 'fxdi406',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi406/fxdi406.module#Fxdi406Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi406'
      }
    }
  },
  {
    path: 'fxdi080',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi080/fxdi080.module#Fxdi080Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi080'
      }
    }
  },
  {
    path: 'fxdi081',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi081/fxdi081.module#Fxdi081Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi081'
      }
    }
  },
  {
    path: 'fxbi023',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/b/fxbi023/fxbi023.module#Fxbi023Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxbi023'
      }
    }
  },
  {
    path: 'fxai025',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai025/fxai025.module#Fxai025Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai025'
      }
    }
  },
  {
    path: 'fxai026',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai026/fxai026.module#Fxai026Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai026'
      }
    }
  },
  {
    path: 'fxbi021',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/b/fxbi021/fxbi021.module#Fxbi021Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxbi021'
      }
    }
  },
  {
    path: 'fxci005',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/c/fxci005/fxci005.module#Fxci005Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxci005'
      }
    }
  },
  {
    path: 'fxci006',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/c/fxci006/fxci006.module#Fxci006Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxci006'
      }
    }
  },
  {
    path: 'fxci007',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/c/fxci007/fxci007.module#Fxci007Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxci007'
      }
    }
  },
  {
    path: 'fxci008',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/c/fxci008/fxci008.module#Fxci008Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxci008'
      }
    }
  },
  {
    path: 'fxci001',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/c/fxci001/fxci001.module#Fxci001Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxci001'
      }
    }
  },
  {
    path: 'fxci009',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/c/fxci009/fxci009.module#Fxci009Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxci009'
      }
    }
  },
  {
    path: 'fxci018',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/c/fxci018/fxci018.module#Fxci018Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxci018'
      }
    }
  },
  {
    path: 'fxci010',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/c/fxci010/fxci010.module#Fxci010Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxci010'
      }
    }
  },
  {
    path: 'fxci011',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/c/fxci011/fxci011.module#Fxci011Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxci011'
      }
    }
  },
  {
    path: 'fxci012',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/c/fxci012/fxci012.module#Fxci012Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxci012'
      }
    }
  },
  {
    path: 'fxdi002',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi002/fxdi002.module#Fxdi002Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi002'
      }
    }
  },
  {
    path: 'fxci002',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/c/fxci002/fxci002.module#Fxci002Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxci002'
      }
    }
  },
  {
    path: 'fxed001',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed001/fxed001.module#Fxed001Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed001'
      }
    }
  },
  {
    path: 'fxei001',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxei001/fxei001.module#Fxei001Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxei001'
      }
    }
  },
  {
    path: 'fxei005',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxei005/fxei005.module#Fxei005Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxei005'
      }
    }
  },
  {
    path: 'fxeq001',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq001/fxeq001.module#Fxeq001Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq001'
      }
    }
  },
  {
    path: 'fxeq002',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq002/fxeq002.module#Fxeq002Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq002'
      }
    }
  },
  {
    path: 'fxeq003',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq003/fxeq003.module#Fxeq003Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq003'
      }
    }
  },
  {
    path: 'fxeq064',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq064/fxeq064.module#Fxeq064Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq064'
      }
    }
  },
  {
    path: 'fxeq067',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq067/fxeq067.module#Fxeq067Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq067'
      }
    }
  },
  {
    path: 'fxci003',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/c/fxci003/fxci003.module#Fxci003Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxci003'
      }
    }
  },
  {
    path: 'fxdi005',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi005/fxdi005.module#Fxdi005Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi005'
      }
    }
  },
  {
    path: 'fxdi056',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi056/fxdi056.module#Fxdi056Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi056'
      }
    }
  },
  {
    path: 'fxci004',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/c/fxci004/fxci004.module#Fxci004Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxci004'
      }
    }
  },
  {
    path: 'fxdi006',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi006/fxdi006.module#Fxdi006Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi006'
      }
    }
  },
  {
    path: 'fxed002',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed002/fxed002.module#Fxed002Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed002'
      }
    }
  },
  {
    path: 'fxgi001',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/g/fxgi001/fxgi001.module#Fxgi001Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxgi001'
      }
    }
  },
  {
    path: 'fxhi005',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/h/fxhi005/fxhi005.module#Fxhi005Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxhi005'
      }
    }
  },
  {
    path: 'fxhp001',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/h/fxhp001/fxhp001.module#Fxhp001Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxhp001'
      }
    }
  },
  {
    path: 'fxhp002',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/h/fxhp002/fxhp002.module#Fxhp002Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxhp002'
      }
    }
  },
  {
    path: 'fxhi002',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/h/fxhi002/fxhi002.module#Fxhi002Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxhi002'
      }
    }
  },
  {
    path: 'fxdi201',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi201/fxdi201.module#Fxdi201Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi201'
      }
    }
  },
  {
    path: 'fxii001',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/i/fxii001/fxii001.module#Fxii001Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxii001'
      }
    }
  },
  {
    path: 'fxii002',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/i/fxii002/fxii002.module#Fxii002Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxii002'
      }
    }
  },
  {
    path: 'fxhi001',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/h/fxhi001/fxhi001.module#Fxhi001Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxhi001'
      }
    }
  },
  {
    path: 'fxii003',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/i/fxii003/fxii003.module#Fxii003Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxii003'
      }
    }
  },
  {
    path: 'fxii004',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/i/fxii004/fxii004.module#Fxii004Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxii004'
      }
    }
  },
  {
    path: 'fxii006',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/i/fxii006/fxii006.module#Fxii006Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxii006'
      }
    }
  },
  {
    path: 'fxii009',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/i/fxii009/fxii009.module#Fxii009Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxii009'
      }
    }
  },
  {
    path: 'fxhd001',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/h/fxhd001/fxhd001.module#Fxhd001Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxhd001'
      }
    }
  },
  {
    path: 'fxed010',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed010/fxed010.module#Fxed010Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed010'
      }
    }
  },
  {
    path: 'fxed011',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed011/fxed011.module#Fxed011Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed011'
      }
    }
  },
  {
    path: 'fxed015',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed015/fxed015.module#Fxed015Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed015'
      }
    }
  },
  {
    path: 'fxed016',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed016/fxed016.module#Fxed016Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed016'
      }
    }
  },
  {
    path: 'fxed012',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed012/fxed012.module#Fxed012Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed012'
      }
    }
  },
  {
    path: 'fxed017',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed017/fxed017.module#Fxed017Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed017'
      }
    }
  },
  {
    path: 'fxed018',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed018/fxed018.module#Fxed018Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed018'
      }
    }
  },
  {
    path: 'fxed019',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed019/fxed019.module#Fxed019Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed019'
      }
    }
  },
  {
    path: 'fxed045',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed045/fxed045.module#Fxed045Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed045'
      }
    }
  },
  {
    path: 'fxed046',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed046/fxed046.module#Fxed046Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed046'
      }
    }
  },
  {
    path: 'fxfd001',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/f/fxfd001/fxfd001.module#Fxfd001Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxfd001'
      }
    }
  },
  {
    path: 'fxbi050',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/b/fxbi050/fxbi050.module#Fxbi050Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxbi050'
      }
    }
  },
  {
    path: 'fxed003',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed003/fxed003.module#Fxed003Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed003'
      }
    }
  },
  {
    path: 'fxed004',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed004/fxed004.module#Fxed004Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed004'
      }
    }
  },
  {
    path: 'fxed005',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed005/fxed005.module#Fxed005Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed005'
      }
    }
  },
  {
    path: 'fxed007',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed007/fxed007.module#Fxed007Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed007'
      }
    }
  },
  {
    path: 'fxed008',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed008/fxed008.module#Fxed008Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed008'
      }
    }
  },
  {
    path: 'fxed009',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed009/fxed009.module#Fxed009Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed009'
      }
    }
  },
  {
    path: 'fxed047',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed047/fxed047.module#Fxed047Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed047'
      }
    }
  },
  {
    path: 'fxed050',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed050/fxed050.module#Fxed050Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed050'
      }
    }
  },
  {
    path: 'fxed052',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed052/fxed052.module#Fxed052Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed052'
      }
    }
  },
  {
    path: 'fxed021',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed021/fxed021.module#Fxed021Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed021'
      }
    }
  },
  {
    path: 'fxed030',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed030/fxed030.module#Fxed030Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed030'
      }
    }
  },
  {
    path: 'fxbi051',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/b/fxbi051/fxbi051.module#Fxbi051Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxbi051'
      }
    }
  },
  {
    path: 'fxbi052',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/b/fxbi052/fxbi052.module#Fxbi052Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxbi052'
      }
    }
  },
  {
    path: 'fxbi060',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/b/fxbi060/fxbi060.module#Fxbi060Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxbi060'
      }
    }
  },
  {
    path: 'fxbi061',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/b/fxbi061/fxbi061.module#Fxbi061Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxbi061'
      }
    }
  },
  {
    path: 'fxgd002',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/g/fxgd002/fxgd002.module#Fxgd002Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxgd002'
      }
    }
  },
  {
    path: 'fxci014',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/c/fxci014/fxci014.module#Fxci014Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxci014'
      }
    }
  },
  {
    path: 'fxgd003',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/g/fxgd003/fxgd003.module#Fxgd003Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxgd003'
      }
    }
  },
  {
    path: 'fxgd004',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/g/fxgd004/fxgd004.module#Fxgd004Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxgd004'
      }
    }
  },
  {
    path: 'fxgd005',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/g/fxgd005/fxgd005.module#Fxgd005Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxgd005'
      }
    }
  },
  {
    path: 'fxgq001',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/g/fxgq001/fxgq001.module#Fxgq001Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxgq001'
      }
    }
  },
  {
    path: 'fxgq111',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/g/fxgq111/fxgq111.module#Fxgq111Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxgq111'
      }
    }
  },
  {
    path: 'fxdi010',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi010/fxdi010.module#Fxdi010Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi010'
      }
    }
  },
  {
    path: 'fxai032',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai032/fxai032.module#Fxai032Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai032'
      }
    }
  },
  {
    path: 'fxai039',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai039/fxai039.module#Fxai039Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai039'
      }
    }
  },
  {
    path: 'fxai040',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai040/fxai040.module#Fxai040Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai040'
      }
    }
  },
  {
    path: 'fxai902',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai902/fxai902.module#Fxai902Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai902'
      }
    }
  },
  {
    path: 'fxai903',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai903/fxai903.module#Fxai903Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai903'
      }
    }
  },
  {
    path: 'fxdi042',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi042/fxdi042.module#Fxdi042Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi042'
      }
    }
  },
  {
    path: 'fxai900',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai900/fxai900.module#Fxai900Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai900'
      }
    }
  },
  {
    path: 'fxai009',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai009/fxai009.module#Fxai009Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai009',
      }
    }
  },
  {
    path: 'fxai901',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai901/fxai901.module#Fxai901Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai901'
      }
    }
  },
  {
    path: 'fxdi024',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi024/fxdi024.module#Fxdi024Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi024'
      }
    }
  },
  {
    path: 'fxdi028',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi028/fxdi028.module#Fxdi028Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi028'
      }
    }
  },
  {
    path: 'fxii005',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/i/fxii005/fxii005.module#Fxii005Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxii005'
      }
    }
  },
  {
    path: 'fxdi051',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi051/fxdi051.module#Fxdi051Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi051'
      }
    }
  },
  {
    path: 'fxdi052',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi052/fxdi052.module#Fxdi052Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi052'
      }
    }
  },
  {
    path: 'fxdi405',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi405/fxdi405.module#Fxdi405Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi405'
      }
    }
  },
  {
    path: 'fxdq001',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdq001/fxdq001.module#Fxdq001Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdq001'
      }
    }
  },
  {
    path: 'fxdq002',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdq002/fxdq002.module#Fxdq002Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdq002'
      }
    }
  },
  {
    path: 'fxdq026',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdq026/fxdq026.module#Fxdq026Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdq026'
      }
    }
  },
  {
    path: 'fxed020',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed020/fxed020.module#Fxed020Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed020'
      }
    }
  },
  {
    path: 'fxed022',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed022/fxed022.module#Fxed022Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed022'
      }
    }
  },
  {
    path: 'fxed023',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed023/fxed023.module#Fxed023Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed023'
      }
    }
  },
  {
    path: 'fxed024',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed024/fxed024.module#Fxed024Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed024'
      }
    }
  },
  {
    path: 'fxdd025',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdd025/fxdd025.module#Fxdd025Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdd025'
      }
    }
  },
  {
    path: 'fxdd050',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdd050/fxdd050.module#Fxdd050Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdd050'
      }
    }
  },
  {
    path: 'fxdd054',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdd054/fxdd054.module#Fxdd054Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdd054'
      }
    }
  },
  {
    path: 'fxdd055',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdd055/fxdd055.module#Fxdd055Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdd055'
      }
    }
  },
  {
    path: 'fxdd008',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdd008/fxdd008.module#Fxdd008Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdd008'
      }
    }
  },
  {
    path: 'fxdd009',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdd009/fxdd009.module#Fxdd009Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdd009'
      }
    }
  },
  {
    path: 'fxbi022',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/b/fxbi022/fxbi022.module#Fxbi022Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxbi022'
      }
    }
  },
  {
    path: 'fxbq022',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/b/fxbq022/fxbq022.module#Fxbq022Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxbq022'
      }
    }
  },
  {
    path: 'fxai008',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai008/fxai008.module#Fxai008Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai008'
      }
    }
  },
  {
    path: 'fxaq008',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxaq008/fxaq008.module#Fxaq008Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxaq008'
      }
    }
  },
  {
    path: 'fxaq017',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxaq017/fxaq017.module#Fxaq017Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxaq017'
      }
    }
  },
  {
    path: 'fxeq034',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq034/fxeq034.module#Fxeq034Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq034'
      }
    }
  },
  {
    path: 'fxeq063',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq063/fxeq063.module#Fxeq063Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq063'
      }
    }
  },
  {
    path: 'fxeq062',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq062/fxeq062.module#Fxeq062Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq062'
      }
    }
  },
  {
    path: 'fxeq061',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq061/fxeq061.module#Fxeq061Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq061'
      }
    }
  },
  {
    path: 'fxed013',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed013/fxed013.module#Fxed013Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed013'
      }
    }
  },
  {
    path: 'fxed014',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed014/fxed014.module#Fxed014Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed014'
      }
    }
  },
  {
    path: 'fxeq035',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq035/fxeq035.module#Fxeq035Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq035'
      }
    }
  },
  {
    path: 'fxeq036',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq036/fxeq036.module#Fxeq036Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq036'
      }
    }
  },
  {
    path: 'fxeq037',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq037/fxeq037.module#Fxeq037Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq037'
      }
    }
  },
  {
    path: 'fxhi006',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/h/fxhi006/fxhi006.module#Fxhi006Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxhi006'
      }
    }
  },
  {
    path: 'fxeq039',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq039/fxeq039.module#Fxeq039Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq039'
      }
    }
  },
  {
    path: 'fxed043',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed043/fxed043.module#Fxed043Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed043'
      }
    }
  },
  {
    path: 'fxed044',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxed044/fxed044.module#Fxed044Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxed044'
      }
    }
  },
  {
    path: 'fxeq011',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq011/fxeq011.module#Fxeq011Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq011'
      }
    }
  },
  {
    path: 'fxeq012',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq012/fxeq012.module#Fxeq012Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq012'
      }
    }
  },
  {
    path: 'fxeq013',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq013/fxeq013.module#Fxeq013Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq013'
      }
    }
  },
  {
    path: 'fxeq014',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq014/fxeq014.module#Fxeq014Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq014'
      }
    }
  },
  {
    path: 'fxeq015',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq015/fxeq015.module#Fxeq015Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq015'
      }
    }
  },
  {
    path: 'fxeq016',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq016/fxeq016.module#Fxeq016Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq016'
      }
    }
  },
  {
    path: 'fxeq017',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq017/fxeq017.module#Fxeq017Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq017'
      }
    }
  },
  {
    path: 'fxeq018',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq018/fxeq018.module#Fxeq018Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq018'
      }
    }
  },
  {
    path: 'fxai016',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai016/fxai016.module#Fxai016Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai016'
      }
    }
  },
  {
    path: 'fxdq054',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdq054/fxdq054.module#Fxdq054Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdq054'
      }
    }
  },
  {
    path: 'fxrq001',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/r/fxrq001/fxrq001.module#Fxrq001Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxrq001'
      }
    }
  },
  {
    path: 'fxrq002',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/r/fxrq002/fxrq002.module#Fxrq002Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxrq002'
      }
    }
  },
  {
    path: 'fxrq004',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/r/fxrq004/fxrq004.module#Fxrq004Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxrq004'
      }
    }
  },
  {
    path: 'fxrq016',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/r/fxrq016/fxrq016.module#Fxrq016Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxrq016'
      }
    }
  },
  {
    path: 'fxrq018',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/r/fxrq018/fxrq018.module#Fxrq018Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxrq018'
      }
    }
  },
  {
    path: 'fxeq046',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq046/fxeq046.module#Fxeq046Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq046'
      }
    }
  },
  {
    path: 'fxeq047',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq047/fxeq047.module#Fxeq047Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq047'
      }
    }
  },
  {
    path: 'fxeq048',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq048/fxeq048.module#Fxeq048Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq048'
      }
    }
  },
  {
    path: 'fxeq049',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq049/fxeq049.module#Fxeq049Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq049'
      }
    }
  },
  {
    path: 'fxrq003',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/r/fxrq003/fxrq003.module#Fxrq003Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxrq003'
      }
    }
  },
  {
    path: 'fxfd002',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/f/fxfd002/fxfd002.module#Fxfd002Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxfd002'
      }
    }
  },
  {
    path: 'fxfd003',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/f/fxfd003/fxfd003.module#Fxfd003Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxfd003'
      }
    }
  },
  {
    path: 'fxfd004',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/f/fxfd004/fxfd004.module#Fxfd004Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxfd004'
      }
    }
  },
  {
    path: 'fxxq006',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxq006/fxxq006.module#Fxxq006Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxq006'
      }
    }
  },
  {
    path: 'fxxq005',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxq005/fxxq005.module#Fxxq005Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxq005'
      }
    }
  },
  {
    path: 'fxxq007',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxq007/fxxq007.module#Fxxq007Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxq007'
      }
    }
  },
  {
    path: 'fxxd021',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd021/fxxd021.module#Fxxd021Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd021'
      }
    }
  },
  {
    path: 'fxxd022',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd022/fxxd022.module#Fxxd022Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd022'
      }
    }
  },
  {
    path: 'fxxd023',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd023/fxxd023.module#Fxxd023Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd023'
      }
    }
  },
  {
    path: 'fxxi011',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxi011/fxxi011.module#Fxxi011Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxi011'
      }
    }
  },
  {
    path: 'fxxi012',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxi012/fxxi012.module#Fxxi012Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxi012'
      }
    }
  },
  {
    path: 'fxgq112',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/g/fxgq112/fxgq112.module#Fxgq112Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxgq112'
      }
    }
  },
  {
    path: 'fxdi017',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi017/fxdi017.module#Fxdi017Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi017'
      }
    }
  },
  {
    path: 'fxdi018',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi018/fxdi018.module#Fxdi018Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi018'
      }
    }
  },
  {
    path: 'fxeq122',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/e/fxeq122/fxeq122.module#Fxeq122Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxeq122'
      }
    }
  },
  {
    path: 'fxxd002',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd002/fxxd002.module#Fxxd002Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd002'
      }
    }
  },
  {
    path: 'fxxd061',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd061/fxxd061.module#Fxxd061Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd061'
      }
    }
  },
  {
    path: 'fxxd062',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd062/fxxd062.module#Fxxd062Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd062'
      }
    }
  },
  {
    path: 'fxxd063',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd063/fxxd063.module#Fxxd063Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd063'
      }
    }
  },
  {
    path: 'fxxq011',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxq011/fxxq011.module#Fxxq011Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxq011'
      }
    }
  },
  {
    path: 'fxxq016',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxq016/fxxq016.module#Fxxq016Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxq016'
      }
    }
  },
  {
    path: 'fxxq014',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxq014/fxxq014.module#Fxxq014Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxq014'
      }
    }
  },
  {
    path: 'fxdi039',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdi039/fxdi039.module#Fxdi039Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdi039'
      }
    }
  },
  {
    path: 'fxdd013',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdd013/fxdd013.module#Fxdd013Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdd013'
      }
    }
  },
  {
    path: 'fxdd014',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdd014/fxdd014.module#Fxdd014Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdd014'
      }
    }
  },
  {
    path: 'fxxd003',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd003/fxxd003.module#Fxxd003Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd003'
      }
    }
  },
  {
    path: 'fxxd038',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd038/fxxd038.module#Fxxd038Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd038'
      }
    }
  },
  {
    path: 'fxxd004',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd004/fxxd004.module#Fxxd004Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd004'
      }
    }
  },
  {
    path: 'fxxd006',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd006/fxxd006.module#Fxxd006Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd006'
      }
    }
  },
  {
    path: 'fxdd081',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdd081/fxdd081.module#Fxdd081Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdd081'
      }
    }
  },
  {
    path: 'fxdd084',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdd084/fxdd084.module#Fxdd084Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdd084'
      }
    }
  },
  {
    path: 'fxdq081',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdq081/fxdq081.module#Fxdq081Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdq081'
      }
    }
  },
  {
    path: 'fxdd082',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdd082/fxdd082.module#Fxdd082Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdd082'
      }
    }
  },
  {
    path: 'fxdd086',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdd086/fxdd086.module#Fxdd086Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdd086'
      }
    }
  },
  {
    path: 'fxdd087',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdd087/fxdd087.module#Fxdd087Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdd087'
      }
    }
  },
  {
    path: 'fxdd089',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdd089/fxdd089.module#Fxdd089Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdd089'
      }
    }
  },
  {
    path: 'fxdd090',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdd090/fxdd090.module#Fxdd090Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdd090'
      }
    }
  },
  {
    path: 'fxdd091',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/d/fxdd091/fxdd091.module#Fxdd091Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxdd091'
      }
    }
  },
  {
    path: 'fxxd011',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd011/fxxd011.module#Fxxd011Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd011'
      }
    }
  },
  {
    path: 'fxxd012',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd012/fxxd012.module#Fxxd012Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd012'
      }
    }
  },
  {
    path: 'fxxd037',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd037/fxxd037.module#Fxxd037Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd037'
      }
    }
  },
  {
    path: 'fxxd013',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd013/fxxd013.module#Fxxd013Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd013'
      }
    }
  },
  {
    path: 'fxxd014',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd014/fxxd014.module#Fxxd014Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd014'
      }
    }
  },
  {
    path: 'fxxd015',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd015/fxxd015.module#Fxxd015Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd015'
      }
    }
  },
  {
    path: 'fxxq024',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxq024/fxxq024.module#Fxxq024Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxq024'
      }
    }
  },
  {
    path: 'fxxq025',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxq025/fxxq025.module#Fxxq025Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxq025'
      }
    }
  },
  {
    path: 'fxxq041',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxq041/fxxq041.module#Fxxq041Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxq041'
      }
    }
  },
  {
    path: 'fxxd016',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd016/fxxd016.module#Fxxd016Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd016'
      }
    }
  },
  {
    path: 'fxxd017',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd017/fxxd017.module#Fxxd017Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd017'
      }
    }
  },
  {
    path: 'fxxd019',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxd019/fxxd019.module#Fxxd019Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxd019'
      }
    }
  },
  {
    path: 'fxxq015',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxq015/fxxq015.module#Fxxq015Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxq015'
      }
    }
  },
  {
    path: 'fxai037',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxai037/fxai037.module#Fxai037Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxai037'
      }
    }
  },
  {
    path: 'fxad037',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/a/fxad037/fxad037.module#Fxad037Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxad037'
      }
    }
  },
  {
    path: 'fxxq048',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxq048/fxxq048.module#Fxxq048Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxq048'
      }
    }
  },
  {
    path: 'fxxi005',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxi005/fxxi005.module#Fxxi005Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxi005'
      }
    }
  },
  {
    path: 'fxxi006',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxi006/fxxi006.module#Fxxi006Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxi006'
      }
    }
  },
  {
    path: 'fxxq050',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxq050/fxxq050.module#Fxxq050Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxq050'
      }
    }
  },
  {
    path: 'fxxq068',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxq068/fxxq068.module#Fxxq068Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxq068'
      }
    }
  },
  {
    path: 'fxxq069',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/x/fxxq069/fxxq069.module#Fxxq069Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxxq069'
      }
    }
  },
  {
    path: 'fxbi064',
    pathMatch: 'prefix',
    loadChildren: 'app/implementation/programs/b/fxbi064/fxbi064.module#Fxbi064Module',
    canLoad: [DwAuthGuardService],
    data: {
      dwRouteData: {
        dwAuthId: 'fxbi064'
      }
    }
  }
];
